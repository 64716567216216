import React from "react";
import { Link } from "gatsby";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="navbar-start">
          <p className="full-name-logo logofooter">CL|</p>
          <Link className="navbar-item" to="/cv">
            Curriculum Vitae
          </Link>
          <Link className="navbar-item" to="/blog">
            Blog
          </Link>
          {/* <Link className="navbar-item" to="/contact">
            Contact
          </Link> */}
        </div>
      </footer>
    );
  }
};

export default Footer;
